@tailwind base;
@tailwind components;
@tailwind utilities;

.service{
    background-image: url('../src/assets/images/servicebg.png');
    background-repeat: no-repeat; 
    left: 0;
    right: 0;
    content: 'cover';
    height: 570px;
    background-image: 100%;
    width:100%;
    background-size: cover;
   
}
.container{
    max-width: 1218px ;
    padding:0 20px;
     /* display: flex;  */
    /* justify-content: center;
    margin: auto;  */
}
/* 'sm': '640px',
      'md': '768px',
      'mi':'914px',
      'lg': '1024px',
      'xl': '1280px', */
@media screen and (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }
  @media screen and (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }
  @media screen and (min-width: 914px) {
    .container {
      max-width: 914px;
    }
  }
  @media screen and (min-width: 1024px) {
    .container {
      max-width: 1220px;
    }
  }
.underworkcom{
background-image: url('./assets/images/bg-about.jpg');
right: 0;
content: '';
background-size: cover;
background-repeat: no-repeat; 

}
.fontstyle{
    font-family: 'Rajdhani', sans-serif;
}
.poppins{
    font-family: 'Poppins', sans-serif;
}
.overlay{
    
        position: absolute;
        content: "";
        background-color: rgba(5, 24, 33, 0.902);
        width: 55%;
        height: 60%;
        left: 0;
        bottom: 0;
        z-index: 0;
        background-repeat: no-repeat;
    }
    /* .inherit{
        width: inherit;
    } */
